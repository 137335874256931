import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import './index.css';
import './venue.css';

const VenuePage = () => (
  <Layout>
    <Seo title="Venue" />
    <div className="content">
      <div className="HomeText">
        <h1>The Venue</h1>
        <p>
          Our wedding will be held start to finish at The White Hart Inn, a
          venue licenced to hold civil ceremonies and host the most wonderful
          wedding receptions.
        </p>
        <p>
          The White Hart Inn is a boutique countryside wedding venue, located at
          the edge of the peak district. It is situated within picturesque
          country lanes and luscious fields, all tucked away outside the quiet
          village of South Wingfield, Derbyshire.
        </p>
        <p>
          Whilst most of the day's events will be taking place indoors, we also
          have access to the private terrace and garden area of the venue, where
          food can be served and the bouncy castle will be situated. As long as
          the weather allows, we'd love for our guests to spend time here,
          enjoying the peaceful location and taking in the views of the
          surrounding countryside. There is a private pond area for family of
          the bride and groom at the very bottom of the garden.
        </p>
        <p>
          There is private parking at the back of the venue for guests of the
          wedding. Taxis and minibuses can be booked to take people to and from
          the venue if they live nearby.
        </p>
        <hr />
        <div className="images">
          <StaticImage
            width={500}
            height={281.5}
            src="../assets/images/wh06(1).jpg"
            alt="the white hart"
          />
          <StaticImage
            width={500}
            height={281.5}
            src="../assets/images/wh08.jpg"
            alt="the white hart"
          />
          <StaticImage
            width={500}
            height={281.5}
            src="../assets/images/wh17.jpg"
            alt="the white hart"
          />
          <StaticImage
            width={500}
            height={281.5}
            src="../assets/images/wh03.jpg"
            alt="the white hart"
          />
          <StaticImage
            width={500}
            height={281.5}
            src="../assets/images/wh05(1).jpg"
            alt="the white hart"
          />
          <StaticImage
            width={500}
            height={281.5}
            src="../assets/images/wh18.jpg"
            alt="the white hart"
          />
        </div>
        <hr />
        <div>
          <h3>Address</h3>
          <p>
            The White Hart Inn
            <br />
            Inns Lane
            <br />
            Moorwood Moor
            <br />
            DE55 7NU
          </p>
          <p>
            You can find it here on Google Maps:{' '}
            <Link
              to="https://maps.app.goo.gl/4w62MAeki7EdWwuR7"
              target="_blank"
            >
              The White Hart Inn
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default VenuePage;
